import React from "react";
import { Fade } from "react-slideshow-image";

import "./App.css";

function App() {
  const CTA = "https://first-and-freedom.printful.me/product/unisex-t-shirt";

  const slideImages = [
    {
      url: "thanks-elon-2.JPG",
      caption: "Thank you Elon!",
    },
    {
      url: "thanks-elon-1.JPG",
      caption: "Thank you Elon!",
    },
    {
      url: "thanks-elon-3.JPG",
      caption: "Thank you Elon!",
    },
  ];

  const CtaButton = () => {
    return (
      <a
        href={CTA}
        target="_blank"
        rel="noreferrer"
        aria-label="Order First and Freedom Shirts now!"
        title="Order First and Freedom Shirts now!"
        className="font-extrabold leading tracking-tight uppercase border border-blue-800 bg-blue-500 hover:bg-blue-700 text-white py-2 px-3 lg:py-3 lg:px-6 rounded text-lg lg:text-2xl shadow-lg sm:ml-auto text-nowrap"
      >
        Order Shirt!
      </a>
    );
  };

  return (
    <div className="slide-container">
      {/* Navigation */}
      <nav className="absolute top-0 left-0 z-50  bg-black bg-opacity-50 p-4 w-full flex flex-col sm:flex-row items-center justify-center gap-4 ">
        <h1 className="font-extrabold leading-none tracking-tight text-3xl md:text-4xl lg:text-4xl xl:text-6xl text-white uppercase text-center sm:text-left block text-nowrap">
          First and Freedom
        </h1>
        <CtaButton />
      </nav>
      {/* Slideshow */}
      <Fade autoplay={true} arrows={false} duration={3000}>
        {slideImages.map((slideImage, index) => (
          <div key={index}>
            <div
              className="backdrop"
              style={{
                height: "100vh",
                width: "100vw",
                backgroundImage: `url(${slideImage.url})`,
                position: "absolute",
                zIndex: 1,
              }}
            />
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                backgroundSize: "contain",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                height: "100vh",
                width: "auto",
                backgroundImage: `url(${slideImage.url})`,
                position: "relative",
                zIndex: 5,
              }}
            />
          </div>
        ))}
      </Fade>
    </div>
  );
}

export default App;
